/**
 * Helper function to allow for creating a linear-gradient background
 * image style.
 * 
 * @param {string} imageUrl The url you want to use as a background
 * @param {string} gradient The gradient colors as a string
 * @param {object} extraStyles A object of extra styles you want to add to the element
 * 
 * @returns {object} The styles object
 * 
 * @example
 * import { setBackgroundImageStyle } from './utils';
 * 
 * const BackgroundImage = ({
 *  url = "example.com/png.png",
 *  gradient = 'rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)',
 *  extraStyles = { backgroundPosition: "center top" }
 * }) => (
 *  <div style={setBackgroundImageStyle(url, gradient, extraStyles)}>Some text</div>
 * );
*/
export const setBackgroundImageStyle = (imageUrl, gradient, extraStyles = {}) => {
  return {
    backgroundImage: `${gradient ? 'linear-gradient('+ gradient +'), ' : ''}url(${imageUrl})`,
    ...extraStyles
  };
}