/**
 * @type {{ [from: string]: string }}
*/
export const redirects = {
  '/region/south-central': '/region/david-massey',
  '/region/pac-atlantic': '/region/clay-duncan',
  '/region/pacific-mountain': '/division/kisha-weir',
  '/region/south-atlantic': '/division/chris-shelton',
  '/region/new-england': '/region/ian-aubourg',
  '/region/upper-midwest': '/region/ricky-cheath',
  '/region/southern-california': '/region/chad-coester',
  '/region/northeast': '/region/spiro-kontostergios',
  '/region/sunbelt': '/division/matt-schoolfield',
  '/region/south&central': '/division/matt-schoolfield',
  '/region/third-coast': '/region/bart-evans',
  '/region/pacific-northwest': '/division/kisha-weir',
  '/region/mid-atlantic': '/region/clay-duncan',
  '/region/southeast': '/region/jonathan.garrick',
  '/regional-leader/jim-stryker': '/',
  '/region/mountain-west': '/',
  '/region/northern-california': '/',
  '/regional-leader/michael-ferreira': '/',
  '/executive-leadership': '/leadership/executives',
  '/divisional-regional-salesleadership': '/leadership/salesleaders',
  '/regional-directors': '/leadership/salesleaders',
  '/leadership/regionals': '/leadership/salesleaders',
  '/video-gallery': '/videos'
};