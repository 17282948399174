import { useState, useEffect } from 'react';

/**
 * Hook for using the `window.matchMedia` function.
 * 
 * @param {string} mediaQuery The media query to watch for.
 * 
 * @returns {boolean} returns true or false whether the media query matches the window size.
 * 
 * @example
 * import React from 'react';
 * import { useMediaQuery } from '../utils';
 * 
 * const Example = ({ children }) => {
 *  const isMobile = useMediaQuery('(max-width: 640px)');
 *  
 *  return (
 *    <div className="media-query-container">
 *      {(isMobile) && (<div className="mobile">{children}</div>)}
 *    </div>
 *  );
 * }
*/
export const useMediaQuery = (mediaQuery) => {
  const initial = ('matchMedia' in window) ? window.matchMedia(mediaQuery).matches : false;
  
  const [matches, setMatches] = useState(initial);

  useEffect(() => {
    const query = window.matchMedia(mediaQuery);

    const onChange = () => setMatches(query.matches);

    query.addEventListener('change', onChange);

    onChange();
    return () => {
      query.removeEventListener('change', onChange);
    }
  }, [mediaQuery, initial])

  return matches;
}