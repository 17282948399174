const urls = process.env.REACT_APP_FONT_URLS ?
  process.env.REACT_APP_FONT_URLS.split(',') :
  [];

const families = process.env.REACT_APP_FONT_FAMILIES ?
  process.env.REACT_APP_FONT_FAMILIES.split(',') :
  [];

export const font = {
  custom: {
    families,
    urls
  },
  timeout: process.env.REACT_APP_FONT_TIMEOUT || 6000
};