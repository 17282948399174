export const wistia = {
  channels: [
    '/videos'
  ],
  ev1: [
    '/region/',
    '/success',
    '/top-producers',
    '/videos',
    '/sales-leader',
    '/regional-leader',
    '/contact',
    '/division'
  ]
}