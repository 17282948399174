import { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

const MEDIA_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.webp',
  '.webm',
  '.gif',
  '.tiff'
]

/**
 * A hook to create imgix urls. Takes the filename, and imgix query parameters
 * as options.
 * 
 * @typedef {Object} ImgixOptions The default options for useImgix
 * @property {boolean} loseless Whether or not you want your image loseless
 * @property {string[]} auto What auto attributes do you want
 * @property {number|string|undefined} w The width of the image
 * @property {number|string|undefined} h The height of the image
 * 
 * @param {string?} filename The filename you want to use.
 * 
 * @param {ImgixOptions?} opts The options you want to use. 
 * 
 * @returns {string?} The imgix url
 * 
 * @example
 * import { useImgix } from './utils';
 * import React from 'react';
 * 
 * const Component = () => {
 *  const opts = {
 *    loseless: true,
 *    auto: ['compress', 'format'],
 *    w: undefined,
 *    h: undefined
 *  };     
 *  const img = useImgix('example.png', opts);
 *  // outputs your image url
 *  return (
 *    <img src={img} />
 *  )
 * }
*/
export const useImgix = (
  filename = '',
  opts = {
    loseless: true,
    auto: ['compress', 'format'],
    w: undefined,
    h: undefined,
    dpr: undefined,
    ar: undefined,
    fit: undefined,
    fill: undefined,
    'fill-color': undefined
  },
  preload = true
) => {
  const [url, setUrl] = useState();
  const BASE_URL = `${process.env.REACT_APP_CDN_BASE_URL}`;
  useLayoutEffect(() => {
    const makeQueryString = () => {
      const qs = [];

      for (let [key, value] of Object.entries(opts)) {
        if (Array.isArray(value)) {
          qs.push(`${key}=${value.join(',')}`);
        } else if (value) {
          qs.push(`${key}=${value}`);
        }
      }

      return qs.join('&');
    }
    
    const qs = makeQueryString();
    if (MEDIA_EXTENSIONS.some(ext => filename.toLowerCase().endsWith(ext))) {
      const uri = `${BASE_URL}/${filename}?${qs}`;
      if (preload) { 
        const image = new Image();
        image.src = uri;
        setUrl(image.src);
      } else {
        setUrl(uri);
      }
    }
  }, [ filename, opts, BASE_URL, preload ]);

  return url;
}

useImgix.propTypes = {
  filename: PropTypes.string,
  opts: PropTypes.shape({
    loseless: PropTypes.bool,
    auto: PropTypes.array,
    w: PropTypes.string,
    h: PropTypes.string
  })
};