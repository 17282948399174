import { useState } from 'react';

/**
 * A hook for using the web storage api. This was taken from 
 * the useHooks team. @link https://usehooks.com/useLocalStorage/
 * 
 * @param {string} key The key to the local storage object
 * @param {object|string|number|object[]|string[]|number[]} initialValue The initial value you want to store.
 * 
 * @returns {array} An array of current stored value and a setter function to set the value.
 * 
 * @example
 * import React from 'react';
 * import { useLocalStorage } from './utils';
 * 
 * const Component = (props) => {
 *  const [ value, setValue ] = useLocalStorage('example', props.exampleText);
 *  return (
 *    <React.Fragment>
 *     <div>{value.example}</div>
 *    </React.Fragment>
 *  );
 * } 
*/
export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}