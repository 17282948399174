import { preview } from '../../config';
export const PrismicPreviewer = () => {
  if (process.env.NODE_ENV !== 'production') {
    preview.forEach((scriptObj) => {
      const script = document.createElement('script');

      if (scriptObj.src) {
        script.src = scriptObj.src;
      } else {
        script.innerHTML = `${scriptObj.innerHTML}`;
      }
      document.body.appendChild(script);
    });
  }

  return null
}