import loadable from '@loadable/component';
import Skeleton from 'react-loading-skeleton';

export { Container } from './Container';
export { BackgroundImage } from './BackgroundImage';
export { PrismicPreviewer } from './PrismicPreviewer';
export { FontProvider } from './FontProvider';
export {
  CookieConsentProvider,
  useCookieConsent
} from './CookieConsentProvider';

export { Tracking } from './Tracking';

export { WistiaScriptLoader } from './WistiaScriptLoader';

export const Videos = loadable(() => import('./Videos/Videos'), {
  cacheKey: () => 'VideosPage',
  ssr: false,
  fallback: <Skeleton count={4} height={500} />
});


export const Contact = loadable(() => import('./Contact/Contact'), {
  cacheKey: () => 'ContactsPage',
  ssr: false
});

export const SingleSales = loadable(() => import('./SingleSales/SingleSales'), {
  cacheKey: () => 'SingleSalesPage',
  ssr: false
});

export const Home = loadable(() => import('./Home/Home'), {
  cacheKey: () => 'HomePage',
  ssr: false
});

export const PathToSuccess = loadable(() => import('./PathToSuccess/PathToSuccess'), {
  cacheKey: () => 'PathToSuccessPage',
  ssr: false
});

export const TopProducers = loadable(() => import('./TopProducers/TopProducers'), {
  cacheKey: () => 'TopProducersPage',
  ssr: false
});

export const Footer = loadable(() => import('./Page/Footer'), {
  cacheKey: () => 'Footer',
  ssr: false
});

export const Navbar = loadable(() => import('./Page/NavBar'), {
  cacheKey: () => 'Navbar',
  ssr: false
});

export const Channel = loadable(() => import('./Videos/Channel'), {
  ssr: false,
  cacheKey: () => 'Channel'
});
