import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

import { PrismicProvider } from '@movement-react/prismic';

import { redirects, font, wistia } from './config';

import {
  Home,
  PathToSuccess,
  TopProducers,
  Videos,
  Contact,
  SingleSales,
  FontProvider,
  Tracking,
  CookieConsentProvider,
  WistiaScriptLoader,
  PrismicPreviewer
} from "./components";



const App = () => (
  <HelmetProvider context={{}}>
    <CookieConsentProvider>
      <FontProvider config={font}>
        <PrismicProvider
          accessToken={process.env.REACT_APP_PRISMIC_ACCESS_TOKEN}
          url={`https://${process.env.REACT_APP_PRISMIC_SITE}.prismic.io/api/v2`}>
          <Router>
            <PrismicPreviewer />
            <WistiaScriptLoader {...wistia} />
            <div className="App">
              <Tracking />
              <Switch>
                
                
                <Route path='/region/kelly-rogers' component={() => { 
                    window.location.href = 'https://lo.movement.com/kelly-rogers/home';
                    return null;
                }}/>
                 <Route path='/contact' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/contact';
                    return null;
                }}/>
                  <Route path='/division/chris-shelton' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='/division/matt-schoolfield' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='/divisional-regional-salesleadership' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='/executive-leadership' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='region/brian-keranen' component={() => { 
                    window.location.href = 'https://lo.movement.com/brian-keranen/home';
                    return null;
                }}/>
                  <Route path='/region/brian-keranen?wtime=%7Bseek_to_second_number%7D?wtime?wtime%3D%7Bseek_to_second_number%7D' component={() => { 
                    window.location.href = 'https://lo.movement.com/brian-keranen/home';
                    return null;
                }}/>
                  <Route path='/region/clay-duncan' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales';
                    return null;
                }}/>
                  <Route path='/region/david-colgan' component={() => { 
                    window.location.href = 'https://lo.movement.com/david-colgan/home';
                    return null;
                }}/>
                  <Route path='/region/david-massey' component={() => { 
                    window.location.href = 'https://lo.movement.com/david-massey/home';
                    return null;
                }}/>
                  <Route path='/region/eli-fairfield' component={() => { 
                    window.location.href = 'https://lo.movement.com/eli-fairfield/home';
                    return null;
                }}/>
                  <Route path='/region/geoff-brown' component={() => { 
                    window.location.href = 'https://lo.movement.com/geoff-brown/home';
                    return null;
                }}/>
                  <Route path='/region/ian-aubourg' component={() => { 
                    window.location.href = 'https://lo.movement.com/ian-aubourg/home';
                    return null;
                }}/>
                  <Route path='/region/ian-aubourg?wtime=%7Bseek_to_second_number%7D?wtime?wtime?wtime?wtime?wtime?wtime?wtime?wtime?wtime?wtime?wtime?wtime?wtime%3D%7Bseek_to_second_number%7D' component={() => { 
                    window.location.href = 'https://lo.movement.com/ian-aubourg/home';
                    return null;
                }}/>
                  <Route path='/region/jonathan.garrick' component={() => { 
                    window.location.href = 'https://lo.movement.com/jonathan-garrick/home';
                    return null;
                }}/>
                  <Route path='/region/ricky-cheath' component={() => { 
                    window.location.href = 'https://lo.movement.com/ricky-cheath/home';
                    return null;
                }}/>
                  <Route path='/region/ryan-hills' component={() => { 
                    window.location.href = 'https://lo.movement.com/ryan-hills/home';
                    return null;
                }}/>
                  <Route path='/regional-directors' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='/regional-leader/chris-shelton' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='/regional-leader/chris-shelton?wtime=%7Bseek_to_second_number%7D?wtime?wtime?wtime?wtime?wtime?wtime?wtime%3D%7Bseek_to_second_number%7D' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/our-leaders';
                    return null;
                }}/>
                  <Route path='/regional-leader/ricky-cheath' component={() => { 
                    window.location.href = 'https://lo.movement.com/ricky-cheath/home';
                    return null;
                }}/>
                  <Route path='/success' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/what-to-expect';
                    return null;
                }}/>
                  <Route path='/success?wtime=%7Bseek_to_second_number%7D?wtime?wtime?wtime?wtime?wtime%3D%7Bseek_to_second_number%7D' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/what-to-expect';
                    return null;
                }}/>
                  <Route path='/top-producers' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/top-producers';
                    return null;
                }}/>
                  <Route path='/video-gallery' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales/what-to-expect';
                    return null;
                }}/>
                  <Route exact path='/*' component={() => { 
                    window.location.href = 'https://movement.com/careers/sales';
                    return null;}}/>

                <Route path="*" component={() => {
                    window.location.href = 'https://movement.com/careers/sales';
                    return null;}}/>
              </Switch>
            </div>
          </Router>
        </PrismicProvider>
      </FontProvider>
    </CookieConsentProvider>
  </HelmetProvider>
);

export default App;
