import { createContext, useCallback, useState } from 'react';
import { CookieConsent } from '../../lib';


export const CookieConsentContext = createContext({
  status: 'requested'
});


const CookieConsentProvider = ({ children }) => {
  const [consented, setConsented] = useState({
    status: 'requested'
  });

  const onCookieConsented = useCallback(
    ({ detail: { status, created } }) =>
      setConsented({ status, created }),
    []
  );

  return (
    <CookieConsentContext.Provider value={consented}>
      {children}
      <CookieConsent>
        {({ CookieConsentDialog }) => (
          <CookieConsentDialog onConsented={onCookieConsented} />
        )}
      </CookieConsent>
    </CookieConsentContext.Provider>

  )
}

export default CookieConsentProvider;