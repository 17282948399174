import loadable from '@loadable/component';

export const CookieConsent = loadable.lib(() => import('@cookie-consent/react'), {
  ssr: false,
  cacheKey: () => 'CookieConsentLib'
});

export const Leaflet = loadable.lib(() => import('leaflet'), {
  ssr: false,
  cacheKey: () => 'LeafletLib'
});

export const ReactFullstory = loadable.lib(() => import('react-fullstory'), {
  ssr: false,
  cacheKey: () => 'FullstoryLib'
});

export const ReactGA = loadable.lib(() => import('react-ga'), {
  ssr: false,
  cacheKey: () => 'ReactGALib'
});

export const ReactFacebookPixel = loadable.lib(() => import('react-facebook-pixel'), {
  ssr: false,
  cacheKey: () => 'ReactFacebookPixelLib'
});

export const ReactModal = loadable.lib(() => import('react-modal'), {
  ssr: false,
  cacheKey: () => 'ReactModalLib'
});

export const ReactFormik = loadable.lib(() => import('formik'), {
  ssr: false,
  cacheKey: () => 'ReactFormikLib'
});

export const Yup = loadable.lib(() => import('yup'), {
  ssr: false,
  cacheKey: () => 'YupLib'
});

export const Webfontloader = loadable.lib(() => import('webfontloader'), {
  ssr: false,
  cacheKey: () => 'WebfontloaderLib'
});

export const ReactTextMask = loadable.lib(() => import('react-text-mask'), {
  ssr: false,
  cacheKey: () => 'ReactTextMaskLib'
})